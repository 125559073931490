<template>
  <main class="page-main">
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'Empty',
};
</script>
